import { mapGetters, mapActions, mapMutations } from "vuex"
import ThemisGeneralConfigurations from "@/components/configurations/general"
import ThemisSystemAccessConfigurations from "@/components/configurations/system-access"
import ThemisSecurityConfigurations from "@/components/configurations/security"
import ThemisDataRetentionRules from "@/components/configurations/data-retention-rules"
import ThemisTermsOfUse from "@/components/configurations/terms-of-use"
import { ANCHOR_LINKS } from "@/constants"

export default {
  name      : "Configurations",
  components: {
    ThemisGeneralConfigurations,
    ThemisSystemAccessConfigurations,
    ThemisSecurityConfigurations,
    ThemisDataRetentionRules,
    ThemisTermsOfUse
  },
  data() {
    return {
      configurationTab                  : null,
      existsOnlyOneDataRetentionPeriod  : false,
      isUserValidationAttributesUpdating: false
    }
  },
  computed: {
    ...mapGetters({
      translationPreferences         : "translationPreferences/translationPreferences",
      isLoadingTranslationPreferences: "translationPreferences/isLoadingTranslationPreferences",
      configurations                 : "configurations/configurations",
      isUpdatingValue                : "configurations/isUpdatingValue",
      isValueUpdated                 : "configurations/isValueUpdated",
      accesses                       : "accesses/accesses",
      roles                          : "roles/roles",
      users                          : "users/users",
      groups                         : "groups/groups",
      isLoadingAccesses              : "accesses/isLoadingAccesses",
      isAccessAdded                  : "accesses/isAccessAdded",
      isAddingAccess                 : "accesses/isAddingAccess",
      isAccessRemoved                : "accesses/isAccessRemoved",
      isRemovingAccess               : "accesses/isRemovingAccess",
      isSsoAllowed                   : "configurations/isSsoAllowed",
      isDefaultSsoConfigured         : "configurations/isDefaultSsoConfigured",
      isDefaultSsoEnabled            : "configurations/isDefaultSsoEnabled",
      defaultSsoConfiguration        : "configurations/defaultSsoConfiguration",
      clientUserPoolId               : "configurations/clientUserPoolId",
      clientConfigurationId          : "configurations/clientConfigurationId",
      isSavingDefaultSsoConfiguration: "configurations/isSavingDefaultSsoConfiguration",
      isDefaultSsoConfigurationSaved : "configurations/isDefaultSsoConfigurationSaved",
      defaultSsoConfigurationError   : "configurations/defaultSsoConfigurationError",
      isEnablingDefaultSsoLogin      : "configurations/isEnablingDefaultSsoLogin",
      isDefaultSsoLoginEnabled       : "configurations/isDefaultSsoLoginEnabled",
      isAiAnonymisationEnabled       : "configurations/isAiAnonymisationEnabled",
      dataRetentionRules             : "dataRetentionRules/dataRetentionRules",
      isUpdatingDataRetentionRule    : "dataRetentionRules/isUpdatingDataRetentionRule",
      dataRetentionPeriods           : "dataRetentionPeriods/dataRetentionPeriods",
      isAddingDataRetentionPeriod    : "dataRetentionPeriods/isAddingDataRetentionPeriod",
      dataRetentionPeriodAddError    : "dataRetentionPeriods/dataRetentionPeriodAddError",
      isDataRetentionPeriodAdded     : "dataRetentionPeriods/isDataRetentionPeriodAdded",
      isRemovingDataRetentionPeriod  : "dataRetentionPeriods/isRemovingDataRetentionPeriod",
      isDataRetentionPeriodRemoved   : "dataRetentionPeriods/isDataRetentionPeriodRemoved",
      fieldsV2                       : "fields/fieldsV2",
      isDataRetentionPeriodsEnabled  : "configurations/isDataRetentionPeriodsEnabled",
      region                         : "auth/region",
      termsOfUse                     : "configurations/termsOfUse",
      isAiPrefillFieldsEnabled       : "configurations/isAiPrefillFieldsEnabled",
      isEnforceMfaEnabled            : "configurations/isEnforceMfaEnabled",
      isAiCaseSummarisationEnabled   : "configurations/isAiCaseSummarisationEnabled",
      isAiSpeechToTextEnabled        : "configurations/isAiSpeechToTextEnabled"
    }),
    tabs() {
      return {
        [ANCHOR_LINKS.GENERAL]       : 0,
        [ANCHOR_LINKS.ACCESS]        : 1,
        [ANCHOR_LINKS.SECURITY]      : 2,
        [ANCHOR_LINKS.DATA_RETENTION]: 3,
        [ANCHOR_LINKS.TERMS_OF_USE]  : 4
      }
    },
    sortedDataRetentionPeriods() {
      return [...this.dataRetentionPeriods].sort((firstDataRetentionPeriod, secondDataRetentionPeriod) =>
        firstDataRetentionPeriod.value - secondDataRetentionPeriod.value)
    },
    translationPreferencesForDisplay() {
      return this.translationPreferences.filter(translationPreference => translationPreference.id !== 5)
    }
  },
  methods: {
    ...mapActions({
      updateConfiguration        : "configurations/updateConfiguration",
      addAccess                  : "accesses/addAccess",
      removeAccess               : "accesses/removeAccess",
      notify                     : "shared/notify",
      saveDefaultSsoConfiguration: "configurations/saveDefaultSsoConfiguration",
      enableDefaultSsoLogin      : "configurations/enableDefaultSsoLogin",
      testDefaultSsoLogin        : "configurations/testDefaultSsoLogin",
      updateDataRetentionRule    : "dataRetentionRules/updateDataRetentionRule",
      addDataRetentionPeriod     : "dataRetentionPeriods/addDataRetentionPeriod",
      removeDataRetentionPeriod  : "dataRetentionPeriods/removeDataRetentionPeriod"
    }),
    ...mapMutations({
      setDefaultSsoConfigurationError: "configurations/setDefaultSsoConfigurationError",
      setDataRetentionPeriodAddError : "dataRetentionPeriods/setDataRetentionPeriodAddError"
    }),
    handleTabClickEvent(hash) {
      this.configurationTab = this.tabs[hash] || this.tabs[ANCHOR_LINKS.GENERAL]
      if (this.$route.hash !== hash) {
        this.$router.push({ hash })
      }
    },
    handleSaveConfigurationForSso(configuration) {
      this.saveDefaultSsoConfiguration(configuration)
    },
    handleSaveUserValidationConfiguration(configuration) {
      this.isUserValidationAttributesUpdating = true
      this.saveDefaultSsoConfiguration(configuration)
    },
    handleUpdateConfiguration(updateConfigurationValue) {
      this.updateConfiguration(updateConfigurationValue)
    },
    handleEnforceMfa(value) {
      this.updateConfiguration({
        id   : 37,
        value: value.toString()
      })
    },
    handleAddAccess(addAccessValue) {
      this.addAccess(addAccessValue)
    },
    handleRemoveAccess(removeAccessValue) {
      this.removeAccess(removeAccessValue)
    },
    handleUpdateDataRetentionRule(rule) {
      this.updateDataRetentionRule({ id: rule.id, value: rule.option })
    },
    handleAddDataRetentionPeriod(customRetentionPeriod) {
      this.addDataRetentionPeriod({
        value: parseInt(customRetentionPeriod)
      })
    },
    resetDataRetentionPeriodAddError() {
      this.setDataRetentionPeriodAddError()
    },
    handleRemoveDataRetentionPeriod(id) {
      if (this.dataRetentionPeriods.length === 1) {
        this.existsOnlyOneDataRetentionPeriod = true
      }
      this.removeDataRetentionPeriod({ id })
    },
    handlePublishTermsOfUse(value) {
      const id = this.termsOfUse.id
      this.updateConfiguration({ id, value })
    }
  },
  watch: {
    isAccessRemoved: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            icon: "mdi-alert-circle",
            type: "info",
            text: "52"
          })
        }
      }
    },
    isDefaultSsoConfigurationSaved: {
      handler: function(newValue) {
        if (newValue) {
          if (this.isUserValidationAttributesUpdating) {
            if (this.defaultSsoConfiguration.ssoValidationKey &&
                this.defaultSsoConfiguration.ssoValidationValue) {
              this.notify({
                type: "success",
                text: "1687"
              })
            }
            this.isUserValidationAttributesUpdating = false
          } else {
            this.notify({
              type: "success",
              text: this.isDefaultSsoConfigured ? "879" : "866"
            })
          }
        }
      }
    },
    isDefaultSsoLoginEnabled: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "888"
          })
        }
      }
    },

    defaultSsoConfigurationError: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "error",
            text: "873"
          })
          this.isConfigurationForSsoError = true
        }
      }
    },
    "$route.hash": {
      immediate: true,
      handler  : function(hash) {
        if (hash) {
          this.handleTabClickEvent(hash)
        }
      }
    },
    isDataRetentionPeriodRemoved: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && this.existsOnlyOneDataRetentionPeriod) {
          this.notify({
            type: "success",
            text: "1309"
          })
          this.existsOnlyOneDataRetentionPeriod = false
        }
      }
    },
    "termsOfUse.value": {
      handler(newValue, oldValue) {
        let locale
        if (newValue && !oldValue) {
          locale = "1622" // Add
        } else if (newValue && newValue !== oldValue) {
          locale = "1653" // Update
        } else if (!newValue && oldValue) {
          locale = "1659" // Delete
        }
        if (locale && this.isValueUpdated) {
          this.notify({
            type: "success",
            text: locale
          })
        }
      }
    }
  }
}